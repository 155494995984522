<template>
  <div>
    <label :class="{ empty: !data }">
      {{ label }}
      <input type="text" v-model="data" />
    </label>
    <div class="clearfix" />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    label: [String, Number],
    value: [String, Number]
  },
  data() {
    return {
      data: this.value
    };
  },
  watch: {
    data() {
      this.callback(this.data);
    }
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
label
  position: relative
  width: 100%
  box-sizing: border-box
  display: block
  padding-left: 8px
  padding-top: 2px
  font-size: 8pt
  color: $success
  height: 30px
  transition: all 0.2s ease-out
  &.empty, &:focus
    font-size: 12pt
    padding-top: 12px
  input[type=text]
    border-radius: 4px
    position: absolute
    box-sizing: border-box
    top: 0
    left: 0px
    font-weight: bold
    background-color: transparent
    border: 1px solid $success
    width: 100%
    padding: 8px
    padding-top: 16px
    color: $dark-grey
    font-size: 16pt
    &:focus
      outline: none
.clearfix
  display: block
  float: none
  clear: both
  height: 30px
</style>
